import * as React from "react"
import { ToggleSwitch } from "gatsby-interface"
import { billing as text } from "../../locales/default.json"
import { visuallyHiddenCss } from "../shared/styles/a11y"
import {
  supportedPlans,
  supportedPlansOrder,
  BillingInterval,
} from "./constants"
import { PlanInformationCard } from "./PlanInformationCard"
import { FormattedMessage } from "../../locales/components/FormattedMessage"
import { getPlanButtonCss, getColorsForPlanTier } from "./helpers"
import { Link } from "gatsby"

const intervalSelectorCss = theme => ({
  marginBottom: theme.space[7],
})

const plansListCss = theme => ({
  display: `grid`,
  gridGap: theme.space[5],
  marginBottom: theme.space[9],
})

export function PlanSelector({
  planOptions,
  selectedPlan,
  onSelectPlan,
  selectedInterval,
  onSelectInterval,
  getPlanButtonLabel,
  getPlanButtonTo,
  getPlanHint,
  renderAction,
}) {
  const optionsContainerCss = theme => [
    plansListCss(theme),
    {
      [theme.mediaQueries.desktop]: {
        gridTemplateColumns: `repeat(${planOptions.length}, 1fr)`,
      },
    },
  ]

  return (
    <div>
      <div css={intervalSelectorCss}>
        <div css={visuallyHiddenCss} id="billingPlans__intervalTitle">
          {text.labels.billingInterval}
        </div>
        <ToggleSwitch
          id="billingPlans__interval"
          valueOn={BillingInterval.Annual}
          labelOn={text.labels.billingIntervalOptionAnnual}
          valueOff={BillingInterval.Monthly}
          labelOff={text.labels.billingIntervalOptionMonthly}
          value={selectedInterval}
          onChange={e => onSelectInterval(e.target.value)}
          aria-describedby="billingPlans__intervalTitle"
          tone="SUCCESS"
          css={theme => ({
            textTransform: `uppercase`,
            fontFamily: theme.fonts.heading,
          })}
        />
      </div>
      <div css={optionsContainerCss}>
        {planOptions.map(tier => {
          const planInformation = supportedPlans[tier]
          const previousTier =
            supportedPlansOrder[supportedPlansOrder.indexOf(tier) - 1]

          const prevTierPlanColorCss = theme => ({
            color: previousTier
              ? getColorsForPlanTier(previousTier)(theme).primaryColor
              : undefined,
          })

          const amount =
            selectedInterval === BillingInterval.Annual
              ? planInformation.monthlyAmount[BillingInterval.Annual]
              : planInformation.monthlyAmount[BillingInterval.Monthly]

          const buttonCss = getPlanButtonCss(tier)

          let actionElement
          if (renderAction) {
            actionElement = renderAction(tier)
          } else {
            actionElement = getPlanButtonTo ? (
              <Link
                to={getPlanButtonTo(tier)}
                css={buttonCss}
                onClick={() => onSelectPlan(tier)}
              >
                {getPlanButtonLabel(tier)}
              </Link>
            ) : (
              <button css={buttonCss} onClick={() => onSelectPlan(tier)}>
                {getPlanButtonLabel(tier)}
              </button>
            )
          }

          return (
            <PlanInformationCard
              key={tier}
              tier={tier}
              title={planInformation.name}
              description={
                <FormattedMessage
                  message={planInformation.description}
                  tags={{
                    strong: content => <strong>{content}</strong>,
                  }}
                />
              }
              amount={amount}
              featuresIntro={
                planInformation.featuresIntro && previousTier ? (
                  <FormattedMessage
                    message={planInformation.featuresIntro}
                    values={{
                      prevTierPlanName: previousTier
                        ? supportedPlans[previousTier].name
                        : "",
                    }}
                    tags={{
                      strong: content => (
                        <strong css={prevTierPlanColorCss}>{content}</strong>
                      ),
                    }}
                  />
                ) : (
                  undefined
                )
              }
              features={planInformation.features}
              hint={getPlanHint ? getPlanHint(tier) : undefined}
              action={actionElement}
              selected={tier === selectedPlan}
            />
          )
        })}
      </div>
    </div>
  )
}
