import * as React from "react"
import IconSkeleton from "./IconSkeleton"
import { IconProps } from "./types"
import { useTheme } from "../ThemeProvider"

export default function LoginIcon(props: IconProps) {
  const theme = useTheme()
  return (
    <IconSkeleton {...props} iconName="LoginIcon">
      <path
        d="M20.6912 9.67121C21.9773 14.4712 19.1288 19.405 14.3288 20.6912C9.52879 21.9773 4.59498 19.1288 3.30882 14.3288C2.02267 9.52879 4.8712 4.59498 9.67121 3.30882C14.4712 2.02267 19.405 4.8712 20.6912 9.67121Z"
        fill={theme.colors.red[5]}
      />
      <path
        d="M17.3594 10.564C18.1525 13.5239 16.3959 16.5663 13.436 17.3594C10.4761 18.1525 7.43372 16.396 6.64062 13.4361C5.84751 10.4762 7.60405 7.43374 10.564 6.64063C13.5239 5.84753 16.5663 7.60407 17.3594 10.564Z"
        fill={theme.colors.white}
        stroke={theme.colors.purple[60]}
        strokeWidth="1.5"
      />
      <path
        d="M15.4765 11.0684C15.9909 12.9884 14.8515 14.9619 12.9315 15.4764C11.0115 15.9909 9.03799 14.8515 8.52352 12.9314C8.00906 11.0114 9.14848 9.03793 11.0685 8.52346C12.9885 8.009 14.962 9.14841 15.4765 11.0684Z"
        fill={theme.colors.purple[60]}
      />
      <path
        d="M13.324 4.53601C13.7468 3.87024 13.2685 3 12.4798 3L11.5473 3C10.7587 3 10.2804 3.87024 10.7031 4.53601L11.1694 5.27036C11.5622 5.88902 12.465 5.88902 12.8578 5.27036L13.324 4.53601Z"
        fill={theme.colors.red[50]}
      />
      <path
        d="M4.57183 10.6899C3.90615 10.2654 3.03409 10.7435 3.03409 11.533L3.03409 12.4671C3.03409 13.2566 3.90615 13.7347 4.57183 13.3102L5.30404 12.8432C5.92038 12.45 5.92038 11.55 5.30404 11.1569L4.57183 10.6899Z"
        fill={theme.colors.red[50]}
      />
      <path
        d="M10.7031 19.464C10.2804 20.1298 10.7587 21 11.5474 21L12.4799 21C13.2685 21 13.7468 20.1298 13.3241 19.464L12.8578 18.7296C12.465 18.111 11.5622 18.111 11.1694 18.7296L10.7031 19.464Z"
        fill={theme.colors.red[70]}
      />
      <path
        d="M19.4554 13.3101C20.1211 13.7347 20.9932 13.2565 20.9932 12.467L20.9932 11.5448C20.9932 10.7552 20.1211 10.2771 19.4554 10.7017L18.7325 11.1628C18.1162 11.5559 18.1162 12.4559 18.7325 12.849L19.4554 13.3101Z"
        fill={theme.colors.orange[90]}
      />
    </IconSkeleton>
  )
}
