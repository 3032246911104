import React from "react"
import { Button } from "gatsby-interface"
import { MdHelpOutline } from "react-icons/md"
import { useTriggerZendeskWidget } from "../hooks"

const zendeskButtonCss = theme => ({
  zIndex: `99999`,
  position: `fixed`,
  right: `0`,
  bottom: `0`,
  marginRight: theme.space[10],
  marginBottom: theme.space[10],
})

export function ZendeskFloatingWidget({ inline }) {
  const triggerZendeskWidget = useTriggerZendeskWidget()

  return (
    <Button
      tone={inline ? `BRAND` : `NEUTRAL`}
      variant={inline ? `SECONDARY` : `PRIMARY`}
      leftIcon={<MdHelpOutline />}
      onClick={triggerZendeskWidget}
      css={theme => [!inline && zendeskButtonCss(theme)]}
    >
      Help
    </Button>
  )
}
