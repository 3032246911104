import React from "react"
import { MdArrowForward } from "react-icons/md"

import { Heading, Button, LinkButton, AnchorButton } from "gatsby-interface"
import newLineToBr from "../components/local-gatsby-interface/utils/helpers/newLineToBr"

function CtaBlock({ children, title, cta, note, ...rest }) {
  return (
    <div
      css={{
        alignItems: `center`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        textAlign: `center`,
      }}
      {...rest}
    >
      {title && <CtaBlock.Title> {title}</CtaBlock.Title>}
      {cta && <CtaBlock.Button cta={cta} />}
      {note && <CtaBlock.Note note={note} />}
      {children}
    </div>
  )
}

CtaBlock.Title = ({ children, ...rest }) => (
  <Heading css={theme => ({ fontSize: theme.fontSizes[7] })} {...rest}>
    {children}
  </Heading>
)

CtaBlock.Button = ({ children, cta = {}, ...rest }) => {
  const { label, to, href, onClick } = cta

  const buttonProps = {
    onClick,
    size: `XL`,
    css: theme => ({ marginTop: theme.space[8] }),
    rightIcon: label ? <MdArrowForward /> : null,
    children: label || children,
    ...rest,
  }

  if (to) {
    return <LinkButton to={to} {...buttonProps} />
  }

  if (href) {
    return <AnchorButton href={href} {...buttonProps} />
  }

  return <Button {...buttonProps} />
}

CtaBlock.Note = ({ children, note, ...rest }) => {
  const sharedProps = {
    css: theme => ({
      color: theme.colors.purple[60],
      marginTop: theme.space[7],
      fontFamily: theme.fonts.heading,
      lineHeight: 1.35,
      textAlign: `center`,
    }),
    ...rest,
  }

  return note ? (
    <div
      dangerouslySetInnerHTML={{ __html: newLineToBr(note) }}
      {...sharedProps}
    />
  ) : (
    <div {...sharedProps}>{children}</div>
  )
}

export default CtaBlock
