import React from "react"
import { SkipNavTarget } from "../components/shared/components/SkipNav"

export default function PageContent({ children, ...rest }) {
  return (
    <main
      css={{
        marginTop: `5rem`,
      }}
      {...rest}
    >
      <SkipNavTarget />
      {children}
    </main>
  )
}

PageContent.Positioner = ({ children, ...rest }) => (
  <div
    css={{
      width: `100%`,
      position: `relative`,
    }}
    {...rest}
  >
    <div
      css={theme => ({
        margin: `0 auto`,
        maxWidth: `90rem`,
        padding: `0 ${theme.space[7]}`,

        [theme.mediaQueries.phablet]: {
          width: `90%`,
        },
      })}
    >
      {children}
    </div>
  </div>
)
