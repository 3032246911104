import React from "react"
import PropTypes from "prop-types"

export default function HtmlContent({ html, ...rest }) {
  return <div dangerouslySetInnerHTML={{ __html: html }} {...rest} />
}

HtmlContent.propTypes = {
  html: PropTypes.string.isRequired,
}
