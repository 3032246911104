const getPropValue = (obj, key) =>
  key.split(`.`).reduce((o, x) => (o === undefined ? o : o[x]), obj)

export const formatRichContent = contentBlocks => {
  const richContentBlocks = {}

  contentBlocks.forEach(block => {
    if (block.__typename === `ContentfulRichContentBlock`) {
      richContentBlocks[block.contentfulid] = block
    }
  })

  return richContentBlocks
}

/*
 * For plan refresh header
 */
export const formatNewHeaderContent = richContentBlocks => {
  const {
    title: pageTitle,
    subtitle: pageLede,
    badge: calloutCta,
    actions: headerBlockActions,
  } = richContentBlocks?.pricingPage_newHeader || {}

  const calloutAction = headerBlockActions &&
    headerBlockActions.length > 0 && {
      label: headerBlockActions[0].displayTitle,
      to: headerBlockActions[0].url,
    }

  return {
    pageTitle,
    pageLede,
    calloutCta,
    calloutAction,
  }
}

export const formatFaqContent = richContentblocks => {
  const {
    title: faqTitle,
    subtitle: faqSubtitle,
    content: faqBlockContent,
    actions: faqActions,
  } = richContentblocks?.pricingPage_faq || {}

  const faqs =
    faqBlockContent?.reduce((acc, item) => {
      if (item.__typename === `ContentfulFaq`) {
        if (
          item.question &&
          item.category &&
          item.answer?.childMarkdownRemark?.html
        ) {
          const {
            question,
            category,
            answer: {
              childMarkdownRemark: { html: answer },
            },
          } = item

          acc.push({ question, category, answer })
        }
      }

      return acc
    }, []) || []

  const faqCta = faqActions &&
    faqActions.length > 0 && {
      title:
        faqBlockContent &&
        getPropValue(
          faqBlockContent.find(
            item => item.contentfulid === `pricingPage_faq_ctaHeading`
          ),
          `body.internal.content`
        ),
      cta: {
        label: faqActions[0].displayTitle,
        to: faqActions[0].url,
      },
    }

  return { faqTitle, faqSubtitle, faqs, faqCta }
}
