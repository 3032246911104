import * as React from "react"

import { CheckIcon } from "gatsby-interface"
import { interpolateMessage } from "../../locales/utils"
import { MdClose } from "react-icons/md"
import {
  planInformation as text,
  billing as billingText,
} from "../../locales/default.json"

export const columnHeaderCss = theme => ({
  padding: `${theme.space[6]} 0`,
  borderBottom: 0,
  verticalAlign: `top`,
  width: `20%`,
  top: 0,
  position: `sticky`,
  background: `white`,
  textAlign: `center`,
  fontWeight: theme.fontWeights.body,
  zIndex: 3,

  "&:first-of-type": {
    width: `15%`,
  },

  strong: {
    fontSize: theme.fontSizes[4],
    display: `block`,
    marginBottom: theme.space[3],
  },

  p: {
    margin: 0,
    fontSize: theme.fontSizes[0],
    color: theme.colors.grey[50],
    lineHeight: theme.lineHeights.body,
    textAlign: `center`,
    padding: `0 ${theme.space[5]}`,
    marginTop: theme.space[2],
  },
})

const categoryHeaderCss = theme => ({
  background: theme.colors.grey[10],
  fontSize: theme.fontSizes[1],
  whiteSpace: `nowrap`,

  th: {
    border: 0,
    padding: 0,
    left: 0,
    position: `sticky`,

    span: {
      display: `flex`,
      alignItems: `center`,
      padding: `${theme.space[4]} ${theme.space[5]}`,
    },

    svg: {
      marginRight: theme.space[3],
    },
  },

  [theme.mediaQueries.hd]: {
    fontSize: theme.fontSizes[3],
  },
})

const featureRowCss = theme => ({
  lineHeight: theme.lineHeights.dense,

  th: {
    fontWeight: theme.fontWeights.body,
    padding: theme.space[5],
    paddingLeft: theme.space[8],
    fontSize: theme.fontSizes[1],
    background: theme.colors.white,

    "&:first-of-type": {
      position: `sticky`,
      left: 0,
    },
  },

  td: {
    fontWeight: theme.fontWeights.semiBold,
    padding: 0,
    textAlign: `center`,
  },

  small: {
    color: theme.colors.grey[50],
  },

  [theme.mediaQueries.hd]: {
    th: {
      whiteSpace: `nowrap`,
      fontSize: theme.fontSizes[2],
    },
  },
})

const ValueIcon = ({ value }) => {
  return value ? (
    <CheckIcon
      css={theme => ({
        color: theme.colors.green[70],
      })}
      aria-label="Included"
      size="small"
      aria-hidden="false"
    />
  ) : (
    <MdClose
      css={theme => ({
        color: theme.colors.red[60],
      })}
      aria-label="Not included"
    />
  )
}

export const ColumnHeader = ({ tier, tierType }) => {
  const amount = tier?.[`${tierType}MonthlyAmount`]?.MONTHLY
  const roundedAmountInUSD = amount !== null ? Math.round(amount / 100) : null

  return (
    <th id={`${tierType}-${tier.key}`} scope="col" css={columnHeaderCss}>
      <strong css={{ color: tier.color }}>{tier.name}</strong>
      <span
        aria-label={
          roundedAmountInUSD !== null
            ? interpolateMessage(text.messages.monthlyAmountAria, {
                amount: roundedAmountInUSD,
              })
            : undefined
        }
      >
        {amount !== null ? (
          <span>
            $<b>{roundedAmountInUSD}</b> / {billingText.morphemes.month}
          </span>
        ) : (
          <span>{text.messages.custom}</span>
        )}
      </span>
      <p>{tier[`${tierType}Description`]}</p>
    </th>
  )
}

export const CategoryHeader = ({ category, categoryIcon = null }) => {
  const Icon = categoryIcon

  return (
    <tr css={categoryHeaderCss}>
      <th id={category} scope="colgroup">
        <span>
          {categoryIcon && <Icon />} {category}
        </span>
      </th>
      <th colSpan={4} />
    </tr>
  )
}

export const FeatureRow = ({
  feature,
  isLastRow,
  category,
  tiers,
  tierType,
}) => {
  const { name, id, values = [] } = feature

  return (
    <tr
      css={theme => [
        featureRowCss(theme),
        isLastRow && {
          "th, td": {
            border: 0,
          },
        },
      ]}
    >
      <th headers={category} id={id}>
        {name}
      </th>
      {values.map((val, idx) => {
        const isBoolean = typeof val === `boolean`

        return (
          <td
            headers={`${tierType}-${tiers[idx].key} ${
              category ? category : ``
            } ${id}`}
            key={idx}
          >
            {!isBoolean ? val : <ValueIcon value={val} />}
          </td>
        )
      })}
    </tr>
  )
}
