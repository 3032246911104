import * as React from "react"

import { Heading } from "gatsby-interface"
import { planInformation as text } from "../../locales/default.json"
import { supportedTiers } from "../../dashboard/src/modules/billing/shared/constants/tiers"
import { emphesizedWordCss } from "../../dashboard/src/modules/billing/shared/components/PlanTierSelector"
import capitalizeStr from "../../utils/capitalizeStr"
import {
  ColumnHeader,
  CategoryHeader,
  FeatureRow,
  columnHeaderCss,
} from "./TierFeaturesComparison.elements"
import { debounce } from "../../components/shared/helpers/debounce"

const tiers = [
  supportedTiers.FREE,
  supportedTiers.STANDARD,
  supportedTiers.PERFORMANCE,
  supportedTiers.ENTERPRISE,
]

const rootCss = theme => ({
  marginTop: theme.space[12],
})

const headingCss = theme => ({
  fontSize: theme.fontSizes[6],
  marginBottom: theme.space[7],
  textAlign: `center`,
})

const frameCss = theme => ({
  boxShadow: theme.shadows.raised,
  gridTemplateColumns: `50% 50%`,
  border: `2px solid ${theme.colors.purple[10]}`,
  borderRadius: theme.radii[3],
  background: theme.colors.white,
  WebkitOverflowScrolling: `touch`,
})

const tableCss = theme => ({
  display: `inline-block`,
  minWidth: `60rem`,
  color: theme.colors.grey[90],
  tableLayout: `fixed`,
  margin: 0,
  position: `relative`,

  [theme.mediaQueries.hd]: {
    paddingLeft: theme.space[5],
    paddingRight: theme.space[5],
  },
})

const noteCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  margin: theme.space[5],
})

export function TierFeaturesComparison({ tierType, features = [], note }) {
  const tableRef = React.useRef()
  const frameRef = React.useRef()

  const debouncedWindowMeasure = React.useCallback(() =>
    debounce(() => {
      const frame = frameRef.current
      const table = tableRef.current

      if (table.clientWidth > frame.clientWidth) {
        const tableHeight = table.clientHeight
        const maxFrameHeight = window.screen.height * 0.8

        frame.style.height = tableHeight > maxFrameHeight ? `80vh` : `auto`
        frame.style.overflowX = `scroll`
      } else {
        frame.style.overflow = `visible`
        frame.style.height = `auto`
      }
    }, 250)
  )

  React.useEffect(() => {
    const measure = debouncedWindowMeasure()

    measure()

    window.addEventListener("resize", measure)

    return () => {
      window.removeEventListener("resize", measure)
    }
  }, [])

  return (
    <div css={rootCss} id={`${tierType}Details`}>
      <Heading fontVariant="UI" as="h3" css={headingCss} variant="EMPHASIZED">
        <span css={emphesizedWordCss}>{capitalizeStr({ str: tierType })}</span>{" "}
        Plan Details
      </Heading>
      <div css={frameCss} ref={frameRef}>
        <table
          summary={text.featureComparisonSummary}
          css={tableCss}
          ref={tableRef}
        >
          <thead>
            <tr>
              <th css={columnHeaderCss} />
              {tiers.map(tier => (
                <ColumnHeader tier={tier} key={tier.name} tierType={tierType} />
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((group, idx) => {
              const { category, categoryIcon, items } = group

              return (
                <React.Fragment key={idx}>
                  {category && (
                    <CategoryHeader
                      category={category}
                      categoryIcon={categoryIcon}
                    />
                  )}

                  {items &&
                    items.map((item, idx) => (
                      <FeatureRow
                        tiers={tiers}
                        tierType={tierType}
                        category={category}
                        key={item.name}
                        feature={item}
                        isLastRow={items.length - 1 === idx}
                      />
                    ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      {note && <div css={noteCss}>{note}</div>}
    </div>
  )
}
