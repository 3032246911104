/**
 * This function can substitute "placeholders" values in a string with provided replacements
 *
 * For example, if we had a message like this: "Connect to {cmsName}",
 * we could replace "{cmsName}" with an actual CMS name by passing it as the second parameter:
 *
 * const buttonlabel = interpolateMessage<'cmsName'>('Connect to {cmsName}', { cmsName: 'DatoCMS' })
 *
 * This helps us avoid splitting "rich" text copies into multiple messages
 *
 * Note that interpolateMessage accepts a type variable, TValues, which can be used to tell TypeScript
 * which values are expected to be passed to the function
 */
export function interpolateMessage<TValues = void>(
  message: string,
  values: InterpolationValues<TValues>["values"]
): string {
  if (!values || Object.keys(values as object).length === 0) {
    return message
  }

  // If values are provided, use them to replace value placeholders
  return message.replace(/{(.*?)}/gi, substring => {
    const key = (substring.replace(/[{}]/g, "") as unknown) as TValues
    return values[key] ? String(values[key]) : substring
  })
}

export type InterpolationValues<TValues> = [TValues] extends [never]
  ? { values?: undefined }
  : [TValues] extends [string | number]
  ? { values: Record<TValues, string | number> }
  : { values?: undefined }
