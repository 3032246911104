import * as React from "react"
import { FeaturesIcon, LoginIcon, DeployIcon } from "gatsby-interface"

export const buildsFeatures = [
  {
    category: `Builds`,
    categoryIcon: FeaturesIcon,
    items: [
      {
        name: (
          <React.Fragment>
            Build Speed<sup>*</sup>
          </React.Fragment>
        ),
        id: `buildSpeed`,
        values: [`Average`, `Fast`, `Faster`, `Fastest`],
      },
      {
        name: `Available Cores`,
        id: `availableCores`,
        values: [1, 2.5, 4, 8],
      },

      {
        name: `Production Builds`,
        id: `productionBuilds`,
        values: [true, true, true, true],
      },
      {
        name: `Pull/Merge Request Builds`,
        id: `pullMerge`,
        values: [true, true, true, true],
      },
      {
        name: `CMS Preview Builds`,
        id: `cmsPreview`,
        values: [true, true, true, true],
      },
      {
        name: `Lighthouse Reports`,
        id: `lighthouseReports`,
        values: [true, true, true, true],
      },
      {
        name: `Incremental Builds`,
        id: `incrementalBuilds`,
        values: [false, true, true, true],
      },
      {
        name: `Password Protected Builds`,
        id: `passProtected`,
        values: [false, true, true, true],
      },
      {
        name: `Users`,
        id: `users`,
        values: [1, 10, 25, `Unlimited`],
      },
    ],
  },
  {
    category: `Git Providers`,
    categoryIcon: LoginIcon,
    items: [
      {
        name: `Github`,
        id: `github`,
        values: [true, true, true, true],
      },
      {
        name: `GitLab`,
        id: `gitlab`,
        values: [true, true, true, true],
      },
      {
        name: (
          <React.Fragment>
            BitBucket <br />
            <small>(coming soon)</small>
          </React.Fragment>
        ),
        id: `bitbucket`,
        values: [true, true, true, true],
      },
    ],
  },
  {
    category: `External Deploys`,
    categoryIcon: DeployIcon,
    items: [
      {
        name: `S3`,
        id: `s3`,
        values: [true, true, true, true],
      },
      {
        name: `Azure`,
        id: `azure`,
        values: [true, true, true, true],
      },
      {
        name: `Firebase`,
        id: `firebase`,
        values: [true, true, true, true],
      },
      {
        name: `Google Storage`,
        id: `googleStorage`,
        values: [true, true, true, true],
      },
      {
        name: `Fastly`,
        id: `fastly`,
        values: [true, true, true, true],
      },
      {
        name: `Netlify`,
        id: `netlify`,
        values: [true, true, true, true],
      },
      {
        name: `Vercel`,
        id: `vercel`,
        values: [true, true, true, true],
      },
    ],
  },
]

export const hostingFeatures = [
  {
    items: [
      {
        name: `Bandwidth`,
        id: `bandwidth`,
        values: [`100GB`, `300GB`, `1TB`, `Unlimited`],
      },
      {
        name: `Requests`,
        id: `requests`,
        values: [`2M`, `30M`, `80M`, `Custom`],
      },
      {
        name: (
          <React.Fragment>
            Serverless Functions
            <br />
            <small>(coming soon)</small>
          </React.Fragment>
        ),
        id: `serverlessFunctions`,
        values: [
          `25K Invocations`,
          `125K Invocations`,
          `250K Invocations`,
          `Custom`,
        ],
      },
    ],
  },
]
