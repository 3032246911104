const planColorsByTier = {
  Free: theme => ({
    primaryColor: theme.colors.blue[80],
    primaryColorDark: theme.colors.blue[90],
    gradient: `linear-gradient(90deg, ${theme.colors.gatsby} 0%, ${theme.colors.blue[40]} 100%)`,
  }),
  Individual: theme => ({
    primaryColor: theme.colors.orange[90],
    primaryColorDark: theme.colors.red[80],
    gradient: `linear-gradient(90deg, ${theme.colors.blue[40]} 0%, ${theme.colors.yellow[60]} 100%)`,
  }),
  Team: theme => ({
    primaryColor: theme.colors.magenta[60],
    primaryColorDark: theme.colors.magenta[80],
    gradient: `linear-gradient(90deg, ${theme.colors.yellow[60]} 0%, ${theme.colors.magenta[60]} 100%)`,
  }),
  Enterprise: theme => ({
    primaryColor: theme.colors.purple[60],
    primaryColorDark: theme.colors.purple[80],
    gradient: `linear-gradient(90deg, ${theme.colors.magenta[60]} 0%, ${theme.colors.gatsby} 100%)`,
  }),
}

export function getColorsForPlanTier(planTier, isTrialPlan = false) {
  return theme => {
    if (isTrialPlan) {
      return {
        primaryColor: theme.colors.purple[60],
        primaryColorDark: theme.colors.purple[80],
        gradient: `linear-gradient(90deg, ${theme.colors.gatsby} 0%, ${theme.colors.blue[40]} 100%)`,
      }
    }
    return planColorsByTier[planTier](theme)
  }
}

export function getPlanButtonCss(tier, variant = `PRIMARY`) {
  const getPlanColors = getColorsForPlanTier(tier)

  return theme => [
    {
      color: theme.colors.white,
      border: `none`,
      borderRadius: theme.radii[2],
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      minHeight: `36px`,
      width: `100%`,
      margin: 0,
      fontSize: theme.fontSizes[2],
      lineHeight: theme.lineHeights.solid,
      fontWeight: theme.fontWeights.semiBold,
      textDecoration: `none`,
      backgroundColor: getPlanColors(theme).primaryColor,
      "&:hover": {
        backgroundColor: getPlanColors(theme).primaryColorDark,
      },
    },
    variant === `SECONDARY` && {
      background: `transparent`,
      border: `1px solid ${getPlanColors(theme).primaryColor}`,
      color: getPlanColors(theme).primaryColor,
      ":hover": {
        borderColor: getPlanColors(theme).primaryColorDark,
        background: theme.colors.grey[10],
        color: getPlanColors(theme).primaryColor,
      },
    },
  ]
}
