import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { planInformation as planInformationText } from "../../locales/default.json"
import { SupportedPlanTier } from "../billing/constants"
import { useTracker, SegmentEventType } from "../../utils/analytics"
import { getPlanButtonCss } from "../billing/helpers"

export function PlanButton({ tier, className }) {
  const { trackSegment } = useTracker()

  const trackEvent = tier => {
    if (tier === SupportedPlanTier.Free) {
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: "Free",
        },
      })
    } else if (tier === SupportedPlanTier.Individual) {
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: "Individual (trial)",
        },
      })
    } else if (tier === SupportedPlanTier.Team) {
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: "Team (trial)",
        },
      })
    } else if (tier === SupportedPlanTier.Enterprise) {
      trackSegment({
        type: SegmentEventType.Track,
        event: `Clicked Contact Sales`,
        properties: {
          location: "Pricing Page",
          plan: "Enterprise",
        },
      })
    }
  }

  let buttonLabel = "Get started"
  let ariaLabel = undefined
  if (tier === SupportedPlanTier.Free) {
    buttonLabel = planInformationText.actions.pickTier1
  } else if (tier === SupportedPlanTier.Individual) {
    buttonLabel = planInformationText.actions.pickTier2
    ariaLabel = planInformationText.actions.pickTier2Aria
  } else if (tier === SupportedPlanTier.Team) {
    buttonLabel = planInformationText.actions.pickTier3
    ariaLabel = planInformationText.actions.pickTier3Aria
  } else if (tier === SupportedPlanTier.Enterprise) {
    buttonLabel = planInformationText.actions.pickTier4
  }

  return (
    <GatsbyLink
      to={getPathToGetStarted(tier)}
      css={getPlanButtonCss(tier)}
      onClick={() => trackEvent(tier)}
      aria-label={ariaLabel}
      className={className}
    >
      {buttonLabel}
    </GatsbyLink>
  )
}

const getPathToGetStarted = tier => {
  let linkTo = ""
  if (tier === SupportedPlanTier.Free) {
    linkTo = `/dashboard/signup`
  } else if (tier === SupportedPlanTier.Individual) {
    linkTo = `/dashboard/signup?selectedPlan=${tier}`
  } else if (tier === SupportedPlanTier.Team) {
    linkTo = `/dashboard/signup?selectedPlan=${tier}`
  } else if (tier === SupportedPlanTier.Enterprise) {
    linkTo = `/contact-us`
  }

  return linkTo
}
