import React from "react"
import PropTypes from "prop-types"

import { Heading, Button, LinkButton } from "gatsby-interface"
import PageContent from "./PageContent"

const VARIANTS = [`LEFT`, `CENTER`]

function PageHeader({
  variant = `LEFT`,
  children = undefined,
  heading = undefined,
  subheading = undefined,
  lede = undefined,
  actions = undefined,
  note = undefined,
  backgrounds = undefined,
  className = undefined,
}) {
  return (
    <PageContent.Positioner
      css={theme => ({
        padding: `${theme.space[10]} 0 ${theme.space[9]}`,

        "& > div": {
          display: `flex`,
          flexDirection: `column`,
          alignItems: variant === `CENTER` ? `center` : `left`,
          textAlign: variant === `CENTER` ? `center` : `flex-start`,
        },
      })}
      className={className}
    >
      {backgrounds && backgrounds}
      {subheading && (
        <PageHeader.Subheading>{subheading}</PageHeader.Subheading>
      )}
      {heading && <PageHeader.Heading>{heading}</PageHeader.Heading>}
      {lede && <PageHeader.Lede html={lede} />}
      {actions && <PageHeader.Actions actions={actions} />}
      {note && <PageHeader.Note html={note} />}
      {children}
    </PageContent.Positioner>
  )
}

PageHeader.propTypes = {
  variant: PropTypes.oneOf(VARIANTS),
}

PageHeader.Heading = ({ children, ...rest }) => (
  <Heading
    variant="EMPHASIZED"
    as="h1"
    css={theme => ({
      maxWidth: `34rem`,
      fontSize: theme.fontSizes[9],
      position: `relative`,
      letterSpacing: `-0.02em`,

      [theme.mediaQueries.desktop]: {
        fontSize: theme.fontSizes[10],
      },

      b: {
        color: theme.colors.purple[50],
        fontWeight: `inherit`,
      },
    })}
    {...rest}
  >
    {children}
  </Heading>
)

PageHeader.Subheading = ({ children, ...rest }) => (
  <PageHeader.Heading
    as="h3"
    variant="PRIMARY"
    css={theme => ({
      fontSize: theme.fontSizes[5],
      marginBottom: theme.space[7],
      position: `relative`,
    })}
    {...rest}
  >
    {children}
  </PageHeader.Heading>
)

PageHeader.Lede = ({ children, html = undefined, ...rest }) => {
  const style = theme => ({
    maxWidth: `24rem`,
    fontSize: theme.fontSizes[4],
    fontFamily: theme.fonts.heading,
    color: theme.colors.grey[50],
    marginTop: theme.space[7],
    lineHeight: 1.4,
    position: `relative`,
  })

  return html ? (
    <div css={style} dangerouslySetInnerHTML={{ __html: html }} {...rest} />
  ) : (
    <div css={style} {...rest}>
      {children}
    </div>
  )
}

PageHeader.Actions = ({ children, actions, ...rest }) => (
  <div
    css={theme => ({
      display: `flex`,
      flexWrap: `wrap`,
      marginTop: theme.space[12],
      position: `relative`,
    })}
    {...rest}
  >
    {actions
      ? actions.map((item, idx) => (
          <PageHeader.Action
            key={idx}
            action={item}
            variant={idx > 0 ? `GHOST` : undefined}
          />
        ))
      : children}
  </div>
)

PageHeader.Action = ({ children, action = {}, variant, ...rest }) => {
  const { label, to, onClick, icon: Icon } = action

  if (!(label && (to || onClick))) {
    return children
  }

  const buttonProps = {
    size: `XL`,
    onClick,
    variant,
    css: theme => ({
      span: {
        borderBottom:
          variant === `GHOST` ? `1px solid ${theme.colors.purple[40]}` : ``,
      },
    }),
    children: label,
    rightIcon: Icon ? <Icon /> : null,
    "data-gtm-cta-loc": "Hero",
    ...rest,
  }

  if (to) {
    return <LinkButton to={to} {...buttonProps} />
  }

  return <Button {...buttonProps} />
}

PageHeader.Note = ({ children, html, ...rest }) => {
  const style = theme => ({
    fontFamily: theme.fonts.heading,
    marginTop: theme.space[7],
    maxWidth: `16rem`,
    lineHeight: 1.3,
    color: theme.colors.purple[60],
    position: `relative`,
  })

  return html ? (
    <div css={style} dangerouslySetInnerHTML={{ __html: html }} {...rest} />
  ) : (
    <div css={style} {...rest}>
      {children}
    </div>
  )
}

export default PageHeader
