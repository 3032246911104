export const cloudGradientCss = theme => ({
  color: theme.colors.gatsby,
  background: `-webkit-linear-gradient(${theme.colors.blue[60]}, ${theme.colors.gatsby})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
})

export const newPricingHeaderCss = theme => ({
  marginTop: theme.space[6],
  maxWidth: `52rem`,
})

export const newPricingLedeCss = theme => ({
  color: theme.colors.grey[70],
  fontSize: theme.fontSizes[6],
  fontWeight: theme.fontWeights.body,
  maxWidth: `52rem`,
})

export const newPricingActionCss = theme => ({
  alignItems: `center`,
  backgroundColor: theme.colors.grey[10],
  color: theme.colors.grey[70],
  display: `flex`,
  margin: `${theme.space[6]} 0`,
  padding: `${theme.space[4]} ${theme.space[6]}`,
  fontSize: theme.fontSizes[2],
})
